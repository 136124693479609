<template>
  <view-wrapper>
    <template #header>
      <div class="flex items-center w-full">
        <img class="w-[72px] h-[72px] rounded-1/2" src="@client/assets/avatar.png" />
        <div class="flex flex-col h-full ml-20px">
          <span class="text-20px font-500">你好, {{ store.state.user.name }}</span>
          <span class="mt-10px text-14px font-normal text-gray-500">{{ store.state.user.branchName }}</span>
        </div>
      </div>
    </template>

    <div class="flex justify-between">
      <div class="w-screen-xl">
        <div>
          <a-card title="询价单" class="mycard">
            <template #extra>
              <a-button type="link" @click="inquiryIndexClick">全部</a-button>
              <a-button type="primary" @click="inquiryAddNew">+ 新建</a-button>
            </template>
            <a-table
              :data-source="inquiryData"
              :columns="inquiryColumns"
              :loading="inquiryLoading"
              rowKey="id"
              :pagination="false"
            >
              <template #operation="{ record }">
                <a class="text-primary" @click="inquiryDetailClick(record)">查看</a>
              </template>
            </a-table>
          </a-card>
        </div>

        <div class="mt-[20px]">
          <a-card title="采购订单" class="mycard">
            <template #extra>
              <a-button type="link" @click="purchaseIndexClick">全部</a-button>
            </template>
            <div>
              <a-spin :spinning="purchaseLoading">
                <ul v-if="purchaseData.length" class="min-w-[890px]">
                  <li v-for="(item, index) in purchaseData" :key="item.id">
                    <div class="flex items-center">
                      <template v-if="item.vin">
                        <span class="font-bold mr-[7px]">VIN码</span>
                        <span>{{ item.vin }}</span>
                        <span class="mx-[14px]">
                          {{
                            `${item.vehicleBrand} ${item.vehicleSubBrand} ${item.vehicleSystem} ${item.vehicleYear} ${item.vehicleDisplacement}`
                          }}
                        </span>
                        <span>{{ item.vehiclePlateNo || '' }}</span>
                      </template>
                      <span v-else class="font-bold">易损件</span>
                    </div>
                    <div class="flex my-24px">
                      <div :class="['item-divider', { 'item-divider-color': index % 2 }]"></div>
                      <div style="flex: 1">
                        <div v-if="item.showPurchases?.length">
                          <a-table
                            :pagination="false"
                            :showHeader="false"
                            rowKey="id"
                            :data-source="item.showPurchases"
                            :columns="columnsPurchase"
                          >
                            <template #serial="{ record }">
                              <span>订单号</span>
                              <span>{{ record.serialNo }}</span>
                            </template>
                            <template #status="{ record }">
                              <a-badge v-if="record.status.code === 'P'" status="error" text="待接单" />
                              <a-badge v-if="record.status.code === 'C'" status="warning" text="已接单" />
                              <a-badge v-if="record.status.code === 'R'" status="default" text="已取消" />
                            </template>
                            <template #vehicle="{ record }">
                              <a-tooltip :title="record.supplier.name || ''">
                                <span>{{ record.supplier.shortName }}</span>
                              </a-tooltip>
                              <template v-if="record.supplierChatUser">
                                <span style="margin-left: 8px">{{ record.supplierChatUser.name }}</span>
                                <a-tooltip :title="record.supplierChatUser.cellphone || '暂无'">
                                  <PhoneFilled
                                    class="pointer"
                                    style="color: #0d8d8d; transform: rotateY(180deg); margin-left: 8px"
                                  />
                                </a-tooltip>
                                <MessageFilled
                                  class="cursor-pointer operation-model"
                                  style="color: #ffd100; margin-left: 8px"
                                  @click="webim.open(record.supplierChatUser)"
                                />
                              </template>
                            </template>
                            <template #amount="{ record }">
                              <span class="font-bold">{{ formatMoney(record.amount, '￥') }}</span>
                            </template>
                            <template #operation="{ record }">
                              <a-button type="link" class="operation-model" @click="purchaseDetailClick(record)"
                                >查看</a-button
                              >
                            </template>
                          </a-table>
                          <div v-if="item.more" class="more-block">
                            <a-button @click="morePurchaseclick(item)" type="link">
                              显示更多
                              <DownOutlined style="color: #269995" />
                            </a-button>
                          </div>
                        </div>
                        <a-empty :image="simpleImage" v-else />
                      </div>
                    </div>
                  </li>
                </ul>
                <a-empty :image="simpleImage" v-else class="w-full"></a-empty>
              </a-spin>
            </div>
          </a-card>
        </div>
      </div>
      <div class="flex-auto ml-[20px] min-w-[380px]">
        <a-card title="我的关注" :loading="followLoading" class="mycard">
          <template #extra>
            <a-button type="link" @click="collectClick">全部</a-button>
          </template>
          <template v-if="followData.length">
            <div
              :class="['flex items-center justify-between', index && 'mt-4']"
              v-for="(item, index) of followData"
              :key="item.id"
            >
              <span class="text-[14px] text-gray-500">{{ item.supplier.shortName }}</span>
              <span class="text-[14px] text-gray-500">{{ formatArea(item.supplier.area) }}</span>
            </div>
          </template>
          <a-empty v-else class="w-full"></a-empty>
        </a-card>
      </div>
    </div>
  </view-wrapper>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import { Card as ACard, Tooltip as ATooltip, Spin as ASpin, Empty as AEmpty, Badge as ABadge } from 'ant-design-vue'
import { PhoneFilled, MessageFilled, DownOutlined } from '@ant-design/icons-vue'

import { useStore, useTable2, formatDate, formatArea, useAjax, formatMoney, useWebim } from '@vue-mfe/utils'
import type { SupplierState } from '@vue-mfe/utils'
import { cloneDeep } from 'lodash-es'
const simpleImage = ref(AEmpty.PRESENTED_IMAGE_SIMPLE)
const store = useStore()
const router = useRouter()
const webim = useWebim()
interface InquiryState {
  branchName: string
  quoteSupplierCount: number
  commitDatetime: string
  lastCommitDatetime: string
}

interface FollowState {
  id: number
  supplier: SupplierState
}

const {
  data: inquiryData,
  columns: inquiryColumns,
  loading: inquiryLoading
  // actions: inquiryActions
} = useTable2<InquiryState>(
  {
    paging: {
      pageSize: 5
    },
    ajax: {
      get: {
        action: 'GET /client/inquiry'
      }
    },
    columns: [
      // { title: '序号', customRender: ({ index }) => index + 1 },
      { title: 'VIN码', dataIndex: 'vin' },
      {
        title: '车型',
        customRender: ({ record }) =>
          record.vin
            ? `${record.vehicleBrand}${record.vehicleSubBrand ? ' ' + record.vehicleSubBrand : ''} ${
                record.vehicleSystem
              } ${record.vehicleYear} ${record.vehicleDisplacement}`
            : record.vehicleBrand
      },
      { title: '车牌', dataIndex: 'vehiclePlateNo' },
      { title: '询价时间', dataIndex: 'commitDatetime', customRender: ({ text }) => formatDate(text, true) },
      { title: '操作', slots: { customRender: 'operation' } }
    ]
  }
)

const inquiryIndexClick = () => {
  router.push({ path: `/client/purchase/inquiry` })
}
const inquiryAddNew = () => {
  router.push({ path: `/client/purchase/inquiry/add` })
}
const inquiryDetailClick = (row: any) => {
  if (row.inquiryStatus.code === 'C') {
    router.push({ path: `/client/purchase/inquiry/quotation`, query: { id: row.id } })
  } else {
    router.push({ path: `/client/purchase/inquiry/${row.id}`, query: { isReadonly: 'Y' } })
  }
}

const columnsPurchase = [
  {
    width: 300,
    slots: { customRender: 'serial' }
  },
  {
    width: 100,
    slots: { customRender: 'status' }
  },
  {
    width: 300,
    slots: { customRender: 'vehicle' }
  },
  {
    align: 'center',
    slots: { customRender: 'amount' }
  },
  {
    align: 'center',
    slots: { customRender: 'operation' }
  }
]
const { data: purchaseData, loading: purchaseLoading } = useAjax(
  {
    action: 'GET /client/inquiry/purchase',
    params: () => ({ pageIndex: 1, pageSize: 5, purchaseStatus: 'P', purchased: 'Y' }),
    convert: {
      client (data) {
        return data.map(
          (item) => Object.assign(item, { more: item.purchases.length > 3, showPurchases: item.purchases.slice(0, 3) })
        )
      }
    }
  }
)

const purchaseIndexClick = () => {
  router.push({ path: `/client/purchase/order` })
}
const purchaseDetailClick = (row: any) => {
  router.push({ path: `/client/purchase/order/${row.id}` })
}
const morePurchaseclick = (inquiry: any) => {
  const limit = 3
  const { showPurchases, purchases } = inquiry
  if (showPurchases.length + limit >= purchases.length) {
    inquiry.more = false
    inquiry.showPurchases = cloneDeep(purchases)
  } else {
    const initLength = showPurchases.length
    for (let i = initLength; i < initLength + limit; i++) {
      inquiry.showPurchases.push(purchases[i])
    }
  }
}

const { data: followData, loading: followLoading } = useAjax<FollowState>(
  {
    action: 'GET /client/supplier/collect',
    params: () => ({ pageIndex: 1, pageSize: 10 })
  }
)
const collectClick = () => {
  router.push({ path: `/client/partners/collect` })
}
</script>
<style scoped>
.ant-table-tbody > tr > td {
  border-top: 1px solid #f0f0f0;
}
.mycard :deep(.ant-card-head-title) {
  font-weight: bold;
}
</style>
